@import '../../../../styles/color';

.c-white-button {
  background-color: $white;
  color: $blue-darken-3;
  border: 1px solid $blue;
  border-radius: 7px;
  font-weight: 500;
  font-size: 24px;
  padding: 8px 80px;

  &:hover {
    background-color: $blue-light-2;
    color: $blue-darken-3;
  }

  &:active {
    background-color: $blue-darken-4;
    color: $blue-darken-3;
    border: 1px solid transparent;
  }
}

@media (max-width: 640px) {
  .c-white-button {
    width: 100%;
    background-color: #e4f2fc;

    &:active:enabled {
      background-color: #b6cee0;
      border-color: transparent;
    }
  }
}
