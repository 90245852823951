@import '../../../../styles/color';

.header-root {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 136px;
  background: linear-gradient(180deg, #00000027, transparent);
  padding: 0px 70px 12px 70px;

  * {
    transition: all 50ms ease-in-out;
  }

  section {
    display: flex;
  }
  .logo {
    font-weight: bold;
    font-size: 40px;
    color: $white;
  }
  .quiz-button,
  .contact-button {
    width: 170px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    color: $white;
    font-weight: bold;
    font-size: 20px;
    padding: 6px 16px;

    span {
      color: #fff;
    }

    .underline {
      background-color: $white;
      margin-top: 5px;
      width: 80%;
      height: 3px;
    }

    &:hover {
      text-shadow: 3px 3px 3px gray;
      font-size: 24px;

      .underline {
        box-shadow: 3px 3px 3px gray;
        width: 100%;
      }
    }

    &:active {
      color: #505050;
      text-shadow: none;
      font-size: 24px;

      span {
        color: #505050;
        box-shadow: none;
      }

      .underline {
        background-color: #505050;
        box-shadow: none;
        width: 100%;
      }
    }
  }

  .quiz-button {
    margin-left: 80px;
  }

  .neon-effect {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 32px;

    .cloud {
      animation: blinking 3s infinite;

      width: 249px;
      height: 146px;
      background-image: image-set(
        url(/public/assets/images/icon/neon-cloud.png) 1x,
        url(/public/assets/images/icon/neon-cloud@2x.png) 2x,
        url(/public/assets/images/icon/neon-cloud@3x.png) 3x
      );
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
    .neon-hand {
      animation: blinking 3s infinite;
      align-self: flex-end;
      width: 80px;
      height: 80px;
      background-image: image-set(
        url(/public/assets/images/icon/neon-hand.png) 1x,
        url(/public/assets/images/icon/neon-hand@2x.png) 2x,
        url(/public/assets/images/icon/neon-hand@3x.png) 3x
      );
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }
  .contact-button {
    margin-left: 30px;
  }

  .sns-btns {
    gap: 32px;

    button {
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
      background-position: center center;
    }

    .insta-button {
      background-image: url(/public/assets/images/icon/instagram-default.png);

      &:hover {
        background-image: url(/public/assets/images/icon/instagram-hover.png);
      }

      &:active {
        background-image: url(/public/assets/images/icon/instagram-click.png);
      }
    }

    .tictok-button {
      background-image: url(/public/assets/images/icon/tictok-default.png);

      &:hover {
        background-image: url(/public/assets/images/icon/tictok-hover.png);
      }

      &:active {
        background-image: url(/public/assets/images/icon/tictok-click.png);
      }
    }

    .twit-button {
      background-image: url(/public/assets/images/icon/twitter-default.png);

      &:hover {
        background-image: url(/public/assets/images/icon/twitter-hover.png);
      }

      &:active {
        background-image: url(/public/assets/images/icon/twitter-click.png);
      }
    }
  }
}

@keyframes blinking {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  45% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
}

@media (max-width: 760px) {
  .header-root {
    height: 80px;
    padding: 0px 16px 12px 16px;

    .quiz-button,
    .contact-button {
      &:hover,
      :active {
        font-size: 20px;

        .underline {
          width: 80%;
        }
      }
    }

    .quiz-button {
      margin-left: 24px;
    }
    .contact-button {
      margin-left: 0px;
    }

    .sns-btns {
      gap: 0px;
    }
  }
}

@media (max-width: 640px) {
  .header-root {
    height: 80px;
    padding: 0px 16px 12px 16px;

    .logo {
      font-size: 24px;
    }

    .quiz-button,
    .contact-button {
      font-size: 14px;
      width: 90px;
      padding: 0px;

      .underline {
        width: 85%;
      }

      &:hover,
      :active {
        font-size: 14px;

        .underline {
          width: 85%;
        }
      }
    }
    .quiz-button {
      margin-left: 8px;
    }
    .contact-button {
      margin-left: 0px;
    }

    .sns-btns {
      gap: 4px;

      button {
        width: 32px;
        height: 32px;
      }
    }
  }
}
