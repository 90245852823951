.email-form-root {
  display: flex;
  justify-content: center;
  gap: 157px;
  align-items: flex-end;
  width: 100%;

  section {
    &:nth-child(1) {
      flex-shrink: 2;
    }
    &:nth-child(2) {
      flex-shrink: 1;
    }
  }

  .title {
    margin-top: 91px;
    max-width: 634px;
    width: 100%;
    font-size: 48px;
    font-weight: bold;
    word-break: keep-all;
    white-space: nowrap;
  }

  .text {
    margin-top: 29px;
    max-width: 432px;
    font-size: 20px;
    font-weight: 300;
  }

  .input-wrapper {
    margin-top: 20px;
    display: flex;
    height: 48px;
    gap: 4px;
    position: relative;

    .message {
      position: absolute;
      font-size: 14px;
      color: #ff0000;
      top: calc(100% + 5px);
    }
  }

  .email-form-img {
    background-image: image-set(
      url(/public/assets/images/image/thankyou.png) 1x,
      url(/public/assets/images/image/thankyou@2x.png) 2x,
      url(/public/assets/images/image/thankyou@3x.png) 3x
    );
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 311px;
    height: 411px;
  }
}

@media (max-width: 1240px) {
  .email-form-root {
    gap: 0;
    section {
      &:nth-child(1) {
      }
      &:nth-child(2) {
      }
    }

    .title {
    }

    .text {
    }

    .email-form-img {
      display: none;
    }
  }
}
