@import './color';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: 'Noto Sans', sans-serif;
  list-style: none;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #000;
  letter-spacing: -0.6px;
}

input {
  outline: none;
  background-color: transparent;
}

button {
  cursor: pointer;
  outline: none;
  background-color: transparent;
}

html {
  font-size: 16px;
  background: linear-gradient(135deg, $tertiary, $main, $secondary, $white);
  background-attachment: fixed;
  overflow-x: hidden !important;
  touch-action: pan-y;
}

#root {
  min-height: 100vh;
  width: 100%;

  .popup-container {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    touch-action: none;

    &::-webkit-scrollbar {
      display: none;
    }

    // overflow-y:;
    // overflow-x: hidden;

    .backdrop {
      transition: opacity 0.3s;
      position: absolute;
      background-color: #000;
      width: 100%;
      height: 100%;
      opacity: 0.24;
      z-index: 5;
      touch-action: none;
    }

    .popup {
      display: flex;
      justify-content: center;
      width: 100%;
      z-index: 6;
      transition: transform 0.3s ease-in-out;
      transform: translateY(0px);
    }
  }
}

@media (max-width: 640px) {
  html {
    background: none;
    background-attachment: none;
  }
}
