.test-root {
  .test {
    width: 100px;
    height: 100px;
    :nth-of-type(0) {
      background-color: red;
    }
    :nth-of-type(1) {
      background-color: red;
    }
    &:nth-of-type(2) {
      background-color: blue;
    }
    &:nth-of-type(3) {
    }
    &:nth-of-type(4) {
    }
    &:nth-of-type(5) {
    }
    &:nth-of-type(6) {
    }
  }

  &:nth-child(9) {
    background-color: red;
  }

  .test:nth-child(1) {
    background-color: red;
  }
}
