.c-white-button {
  background-color: #ffffff;
  color: #1976ba;
  border: 1px solid #4ea3e1;
  border-radius: 7px;
  font-weight: 500;
  font-size: 24px;
  padding: 8px 80px;
}
.c-white-button:hover {
  background-color: #f2faff;
  color: #1976ba;
}
.c-white-button:active {
  background-color: #dfe7ed;
  color: #1976ba;
  border: 1px solid transparent;
}

@media (max-width: 640px) {
  .c-white-button {
    width: 100%;
    background-color: #e4f2fc;
  }
  .c-white-button:active:enabled {
    background-color: #b6cee0;
    border-color: transparent;
  }
}/*# sourceMappingURL=cbutton.white.style.css.map */