.quiz-question-root {
  width: 100%;
  padding: 0px 88px 96px 90px;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  .question {
    margin-top: 108px;
    font-weight: bold;
    font-size: 48px;
    margin-bottom: 52px;
    word-break: keep-all;
    white-space: nowrap;
  }

  .checkbox-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

@media (max-width: 1300px) {
  .quiz-question-root {
    flex-direction: column;
    align-items: flex-start;
    .question {
      margin-top: 32px;
    }
    .next-button-wrapper {
    }
  }
}

@media (max-width: 640px) {
  .quiz-question-root {
    padding: 0px;
    .question {
      margin-top: 0px;
      margin-bottom: 44px;
      font-size: 36px;
      word-break: normal;
      white-space: normal;
      // text-align: justify;
    }

    .next-button-wrapper {
      width: 100%;
      align-self: center;
    }
  }
}
