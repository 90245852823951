.email-form-m-root {
  .banner {
    width: 100%;
    height: 248px;
    background-image: image-set(
      url(/public/assets/images/image/thankyou.png) 1x,
      url(/public/assets/images/image/thankyou@2x.png) 2x,
      url(/public/assets/images/image/thankyou@3x.png) 3x
    );

    background-repeat: no-repeat;
    background-position: center 75%;
    background-size: cover;
    position: relative;

    .close-button {
      position: absolute;
      top: 64px;
      left: 20px;
      width: 24px;
      height: 24px;
      background-image: image-set(
        url(/public/assets/images/icon/close-button.png) 1x,
        url(/public/assets/images/icon/close-button@2x.png) 2x,
        url(/public/assets/images/icon/close-button@3x.png) 3x
      );
      filter: invert(100%);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }
  }

  .content-wrapper {
    padding: 0px 16px;

    .input-wrapper {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;

      .error-msg {
        position: absolute;
        bottom: -30px;
        color: #ff0000;
        font-size: 16px;
      }
    }

    .title {
      margin-top: 24px;
      font-size: 28px;
      font-weight: bold;
    }
    .text {
      color: #595959;
      text-align: justify;
      margin-top: 20px;
      font-size: 16px;
    }
    .input-wrapper {
      height: 204px;
    }
  }
}
