.quiz-start-root {
  display: flex;
  flex-direction: row-reverse;
  gap: 40px;
  align-items: center;
  justify-content: center;
  padding-top: 68px;

  .quiz-img {
    width: 417px;
  }
  .image-container {
    width: 417px;
    height: 504px;
    position: relative;
    display: flex;

    .phone-img {
      position: absolute;
      bottom: 0px;
      width: 137px;
      height: 206px;
      z-index: 1;
      background-image: image-set(
        url(/public/assets/images/image/phone-img.png) 1x,
        url(/public/assets/images/image/phone-img@2x.png) 2x,
        url(/public/assets/images/image/phone-img@3x.png) 3x
      );
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }

    .bg-img {
      position: absolute;
      left: 111px;
      width: 306px;
      height: 457px;
      background-image: image-set(
        url(/public/assets/images/image/girls-social-img.png) 1x,
        url(/public/assets/images/image/girls-social-img@2x.png) 2x,
        url(/public/assets/images/image/girls-social-img@3x.png) 3x
      );
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }
  }
  .question {
    font-size: 48px;
    font-weight: bold;
  }
  .answer {
    font-size: 20px;
    font-weight: 300;
    margin-top: 16px;
    margin-bottom: 64px;

    .strong {
      display: block;
      font-size: 28px;
      margin-bottom: 28px;
    }
  }
  .start-button {
    &:hover {
      .hand {
        background-image: image-set(
          url(/public/assets/images/icon/hand-colored.png) 1x,
          url(/public/assets/images/icon/hand-colored@2x.png) 2x,
          url(/public/assets/images/icon/hand-colored@3x.png) 3x
        );
      }
    }
  }
  .hand {
    width: 36px;
    height: 36px;
    background-image: image-set(
      url(/public/assets/images/icon/hand.png) 1x,
      url(/public/assets/images/icon/hand@2x.png) 2x,
      url(/public/assets/images/icon/hand@3x.png) 3x
    );
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
}

@media (max-width: 1400px) {
  .quiz-start-root {
    .question {
      font-size: 36px;
      font-weight: bold;
    }
    .answer {
      font-size: 14px;
      font-weight: 300;
      margin-top: 16px;
      margin-bottom: 64px;

      .strong {
        font-size: 18px;
        margin-bottom: 28px;
      }
    }
  }
}

@media (max-width: 1100px) {
  .quiz-start-root {
    .image-container {
      width: 320px;
      height: 400px;
      position: relative;
      display: flex;

      .phone-img {
        position: absolute;
        bottom: 0px;
        width: 120px;
        height: 200px;
        z-index: 1;
        background-image: image-set(
          url(/public/assets/images/image/phone-img.png) 1x,
          url(/public/assets/images/image/phone-img@2x.png) 2x,
          url(/public/assets/images/image/phone-img@3x.png) 3x
        );
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
      }

      .bg-img {
        position: absolute;
        left: 60px;
        width: 240px;
        height: 375px;
        background-image: image-set(
          url(/public/assets/images/image/girls-social-img.png) 1x,
          url(/public/assets/images/image/girls-social-img@2x.png) 2x,
          url(/public/assets/images/image/girls-social-img@3x.png) 3x
        );
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
      }
    }
    .quiz-img {
      width: 320px;
    }
    .question {
      font-size: 28px;
      font-weight: bold;
    }
    .answer {
      font-size: 12px;
      font-weight: 300;
      margin-top: 16px;
      margin-bottom: 64px;

      .strong {
        font-size: 16px;
        margin-bottom: 28px;
      }
    }
  }
}

@media (max-width: 960px) {
  .quiz-start-root {
    .image-container {
      width: 240px;
      height: 300px;
      position: relative;
      display: flex;

      .phone-img {
        position: absolute;
        bottom: 0px;
        width: 80px;
        height: 150px;
        z-index: 1;
        background-image: image-set(
          url(/public/assets/images/image/phone-img.png) 1x,
          url(/public/assets/images/image/phone-img@2x.png) 2x,
          url(/public/assets/images/image/phone-img@3x.png) 3x
        );
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
      }

      .bg-img {
        position: absolute;
        left: 60px;
        width: 160px;
        height: 275px;
        background-image: image-set(
          url(/public/assets/images/image/girls-social-img.png) 1x,
          url(/public/assets/images/image/girls-social-img@2x.png) 2x,
          url(/public/assets/images/image/girls-social-img@3x.png) 3x
        );
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
      }
    }
  }
}

@media (max-width: 640px) {
  .quiz-start-root {
    display: flex;
    flex-direction: column;
    gap: 28px;
    align-items: center;
    padding-top: 0px;

    .quiz-img {
      width: 100%;
    }
    .question {
      font-size: 28px;
      font-weight: bold;
    }
    .answer {
      font-size: 16px;
      font-weight: normal;
      margin-top: 20px;
      margin-bottom: 32px;
      text-align: justify;

      .strong {
        display: inline;
        font-size: 16px;
        margin-bottom: 0px;
      }
    }
  }
}
