.header-root {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 136px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.1529411765), transparent);
  padding: 0px 70px 12px 70px;
}
.header-root * {
  transition: all 50ms ease-in-out;
}
.header-root section {
  display: flex;
}
.header-root .logo {
  font-weight: bold;
  font-size: 40px;
  color: #ffffff;
}
.header-root .quiz-button,
.header-root .contact-button {
  width: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  color: #ffffff;
  font-weight: bold;
  font-size: 20px;
  padding: 6px 16px;
}
.header-root .quiz-button span,
.header-root .contact-button span {
  color: #fff;
}
.header-root .quiz-button .underline,
.header-root .contact-button .underline {
  background-color: #ffffff;
  margin-top: 5px;
  width: 80%;
  height: 3px;
}
.header-root .quiz-button:hover,
.header-root .contact-button:hover {
  text-shadow: 3px 3px 3px gray;
  font-size: 24px;
}
.header-root .quiz-button:hover .underline,
.header-root .contact-button:hover .underline {
  box-shadow: 3px 3px 3px gray;
  width: 100%;
}
.header-root .quiz-button:active,
.header-root .contact-button:active {
  color: #505050;
  text-shadow: none;
  font-size: 24px;
}
.header-root .quiz-button:active span,
.header-root .contact-button:active span {
  color: #505050;
  box-shadow: none;
}
.header-root .quiz-button:active .underline,
.header-root .contact-button:active .underline {
  background-color: #505050;
  box-shadow: none;
  width: 100%;
}
.header-root .quiz-button {
  margin-left: 80px;
}
.header-root .neon-effect {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 32px;
}
.header-root .neon-effect .cloud {
  -webkit-animation: blinking 3s infinite;
          animation: blinking 3s infinite;
  width: 249px;
  height: 146px;
  background-image: -webkit-image-set(url(/public/assets/images/icon/neon-cloud.png) 1x, url(/public/assets/images/icon/neon-cloud@2x.png) 2x, url(/public/assets/images/icon/neon-cloud@3x.png) 3x);
  background-image: image-set(url(/public/assets/images/icon/neon-cloud.png) 1x, url(/public/assets/images/icon/neon-cloud@2x.png) 2x, url(/public/assets/images/icon/neon-cloud@3x.png) 3x);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.header-root .neon-effect .neon-hand {
  -webkit-animation: blinking 3s infinite;
          animation: blinking 3s infinite;
  align-self: flex-end;
  width: 80px;
  height: 80px;
  background-image: -webkit-image-set(url(/public/assets/images/icon/neon-hand.png) 1x, url(/public/assets/images/icon/neon-hand@2x.png) 2x, url(/public/assets/images/icon/neon-hand@3x.png) 3x);
  background-image: image-set(url(/public/assets/images/icon/neon-hand.png) 1x, url(/public/assets/images/icon/neon-hand@2x.png) 2x, url(/public/assets/images/icon/neon-hand@3x.png) 3x);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.header-root .contact-button {
  margin-left: 30px;
}
.header-root .sns-btns {
  gap: 32px;
}
.header-root .sns-btns button {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center center;
}
.header-root .sns-btns .insta-button {
  background-image: url(/public/assets/images/icon/instagram-default.png);
}
.header-root .sns-btns .insta-button:hover {
  background-image: url(/public/assets/images/icon/instagram-hover.png);
}
.header-root .sns-btns .insta-button:active {
  background-image: url(/public/assets/images/icon/instagram-click.png);
}
.header-root .sns-btns .tictok-button {
  background-image: url(/public/assets/images/icon/tictok-default.png);
}
.header-root .sns-btns .tictok-button:hover {
  background-image: url(/public/assets/images/icon/tictok-hover.png);
}
.header-root .sns-btns .tictok-button:active {
  background-image: url(/public/assets/images/icon/tictok-click.png);
}
.header-root .sns-btns .twit-button {
  background-image: url(/public/assets/images/icon/twitter-default.png);
}
.header-root .sns-btns .twit-button:hover {
  background-image: url(/public/assets/images/icon/twitter-hover.png);
}
.header-root .sns-btns .twit-button:active {
  background-image: url(/public/assets/images/icon/twitter-click.png);
}

@-webkit-keyframes blinking {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  45% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
}

@keyframes blinking {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  45% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
}
@media (max-width: 760px) {
  .header-root {
    height: 80px;
    padding: 0px 16px 12px 16px;
  }
  .header-root .quiz-button:hover,
.header-root .quiz-button :active,
.header-root .contact-button:hover,
.header-root .contact-button :active {
    font-size: 20px;
  }
  .header-root .quiz-button:hover .underline,
.header-root .quiz-button :active .underline,
.header-root .contact-button:hover .underline,
.header-root .contact-button :active .underline {
    width: 80%;
  }
  .header-root .quiz-button {
    margin-left: 24px;
  }
  .header-root .contact-button {
    margin-left: 0px;
  }
  .header-root .sns-btns {
    gap: 0px;
  }
}
@media (max-width: 640px) {
  .header-root {
    height: 80px;
    padding: 0px 16px 12px 16px;
  }
  .header-root .logo {
    font-size: 24px;
  }
  .header-root .quiz-button,
.header-root .contact-button {
    font-size: 14px;
    width: 90px;
    padding: 0px;
  }
  .header-root .quiz-button .underline,
.header-root .contact-button .underline {
    width: 85%;
  }
  .header-root .quiz-button:hover,
.header-root .quiz-button :active,
.header-root .contact-button:hover,
.header-root .contact-button :active {
    font-size: 14px;
  }
  .header-root .quiz-button:hover .underline,
.header-root .quiz-button :active .underline,
.header-root .contact-button:hover .underline,
.header-root .contact-button :active .underline {
    width: 85%;
  }
  .header-root .quiz-button {
    margin-left: 8px;
  }
  .header-root .contact-button {
    margin-left: 0px;
  }
  .header-root .sns-btns {
    gap: 4px;
  }
  .header-root .sns-btns button {
    width: 32px;
    height: 32px;
  }
}/*# sourceMappingURL=header.style.css.map */