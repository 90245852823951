.alert-popup-root {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  box-shadow: 5px 5px 3px #505050;
  background-color: #ffffff;
  border-radius: 7px;
  padding: 40px 64px 48px 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.alert-popup-root .coming-soon {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 12px;
}
.alert-popup-root .text {
  text-align: center;
  margin-bottom: 36px;
  font-size: 24px;
}

@media (max-width: 640px) {
  .alert-popup-root {
    padding: 32px 30px 40px 20px;
    max-width: 80vh;
  }
  .alert-popup-root .coming-soon {
    align-self: flex-start;
  }
  .alert-popup-root .text {
    align-self: flex-start;
    text-align: left;
    font-size: 16px;
  }
  .alert-popup-root .discard-button {
    font-size: 16px;
  }
}/*# sourceMappingURL=alert.style.css.map */