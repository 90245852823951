.dropdown-root {
  position: relative;
  width: fit-content;
  .label {
    position: absolute;
    background-color: #fff;
    padding: 0px 4px;
    color: #4ea3e1;
    font-weight: 500;
    font-size: 12px;
    transition: top 0.1s ease-in-out;
    top: 11px;
    left: 8px;
  }

  .dropdown-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border: 1px solid #bcd7eb;
    border-radius: 4px;
    padding: 10px 8px;
    height: 40px;

    .text {
      font-size: 18px;
    }

    .arrow {
      transition: transform 0.1s ease-in-out;
      transform: rotate(180deg);
      background-image: image-set(
        url(/public/assets/images/icon/arrow.png) 1x,
        url(/public/assets/images/icon/arrow@2x.png) 2x,
        url(/public/assets/images/icon/arrow@3x.png) 3x
      );
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      width: 19px;
      height: 19px;
    }
  }

  .options-wrapper {
    position: absolute;
    z-index: 5;
    transition: height 0.1s ease-in-out, padding 0.1s ease-in-out;
    height: 0px;
    top: 56px;
    box-shadow: 3px 3px 3px #f1f1f1;
    border-radius: 7px;
    background-color: #fff;

    .option-list {
      height: 100%;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #c9c9c9;
        height: 30px;
        border-radius: 2px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      .option {
        padding: 4px 8px;
        border-radius: 7px;
        font-size: 14px;

        &:hover,
        &:active {
          background-color: #f2faff;
        }
      }
    }
  }

  &.focused {
    .label {
      top: -4px;
    }

    .dropdown-container {
      border-width: 3px;

      .arrow {
        transform: rotate(0deg);
      }
    }

    .options-wrapper {
      height: 168px;
      padding: 8px;
    }
  }

  &.selected {
    .label {
      top: -4px;
    }

    .dropdown-container {
      border-width: 3px;
      border-color: #4ea3e1;
      padding: 8px;
    }
  }
}
