.bonus-popup-root {
  max-width: 90vw;
  width: 805px;
  height: 366px;
  background-color: #fff;
  box-shadow: 0px 5px 5px #a0a0a0;
  border-radius: 4px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.bonus-popup-root .section-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.bonus-popup-root .link-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.bonus-popup-root .link-section .link {
  width: 524px;
  border: 1px solid #4ea3e1;
  padding: 13px 8px;
  color: #b4b4b4;
  font-size: 20px;
  max-width: 70vw;
  overflow: hidden;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  border-radius: 4px;
  overflow: hidden;
  text-overflow: hidden;
  white-space: nowrap;
}
.bonus-popup-root .link-section .copy-button {
  width: 88px;
  height: 55px;
  background-color: #4ea3e1;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  border-radius: 5px;
  flex-shrink: 0;
}
.bonus-popup-root .link-section .copy-button:active {
  background-color: #2878b1;
}
.bonus-popup-root .divider {
  display: flex;
  align-items: center;
  gap: 9px;
}
.bonus-popup-root .divider .share-text {
  font-size: 20px;
  font-weight: 500;
}
.bonus-popup-root .divider .line {
  flex-grow: 1;
  background-color: #7d7d7d;
  height: 1px;
}
.bonus-popup-root .sns-section {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.bonus-popup-root .sns-section button {
  width: 64px;
  height: 64px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.bonus-popup-root .sns-section .insta-button {
  background-image: -webkit-image-set(url(/public/assets/images/icon/insta-share-btn.png) 1x, url(/public/assets/images/icon/insta-share-btn@2x.png) 2x, url(/public/assets/images/icon/insta-share-btn@3x.png) 3x);
  background-image: image-set(url(/public/assets/images/icon/insta-share-btn.png) 1x, url(/public/assets/images/icon/insta-share-btn@2x.png) 2x, url(/public/assets/images/icon/insta-share-btn@3x.png) 3x);
}
.bonus-popup-root .sns-section .insta-button:active {
  background-image: -webkit-image-set(url(/public/assets/images/icon/insta-share-btn-pressed.png) 1x, url(/public/assets/images/icon/insta-share-btn-pressed@2x.png) 2x, url(/public/assets/images/icon/insta-share-btn-pressed@3x.png) 3x);
  background-image: image-set(url(/public/assets/images/icon/insta-share-btn-pressed.png) 1x, url(/public/assets/images/icon/insta-share-btn-pressed@2x.png) 2x, url(/public/assets/images/icon/insta-share-btn-pressed@3x.png) 3x);
}
.bonus-popup-root .sns-section .twit-button {
  background-image: -webkit-image-set(url(/public/assets/images/icon/twit-share-btn.png) 1x, url(/public/assets/images/icon/twit-share-btn@2x.png) 2x, url(/public/assets/images/icon/twit-share-btn@3x.png) 3x);
  background-image: image-set(url(/public/assets/images/icon/twit-share-btn.png) 1x, url(/public/assets/images/icon/twit-share-btn@2x.png) 2x, url(/public/assets/images/icon/twit-share-btn@3x.png) 3x);
}
.bonus-popup-root .sns-section .twit-button:active {
  background-image: -webkit-image-set(url(/public/assets/images/icon/twit-share-btn-pressed.png) 1x, url(/public/assets/images/icon/twit-share-btn-pressed@2x.png) 2x, url(/public/assets/images/icon/twit-share-btn-pressed@3x.png) 3x);
  background-image: image-set(url(/public/assets/images/icon/twit-share-btn-pressed.png) 1x, url(/public/assets/images/icon/twit-share-btn-pressed@2x.png) 2x, url(/public/assets/images/icon/twit-share-btn-pressed@3x.png) 3x);
}
.bonus-popup-root .sns-section .facebook-button {
  background-image: -webkit-image-set(url(/public/assets/images/icon/face-share-btn.png) 1x, url(/public/assets/images/icon/face-share-btn@2x.png) 2x, url(/public/assets/images/icon/face-share-btn@3x.png) 3x);
  background-image: image-set(url(/public/assets/images/icon/face-share-btn.png) 1x, url(/public/assets/images/icon/face-share-btn@2x.png) 2x, url(/public/assets/images/icon/face-share-btn@3x.png) 3x);
}
.bonus-popup-root .sns-section .facebook-button:active {
  background-image: -webkit-image-set(url(/public/assets/images/icon/face-share-btn-pressed.png) 1x, url(/public/assets/images/icon/face-share-btn-pressed@2x.png) 2x, url(/public/assets/images/icon/face-share-btn-pressed@3x.png) 3x);
  background-image: image-set(url(/public/assets/images/icon/face-share-btn-pressed.png) 1x, url(/public/assets/images/icon/face-share-btn-pressed@2x.png) 2x, url(/public/assets/images/icon/face-share-btn-pressed@3x.png) 3x);
}
.bonus-popup-root .sns-section .kakao-button {
  background-image: -webkit-image-set(url(/public/assets/images/icon/kakao-share-btn.png) 1x, url(/public/assets/images/icon/kakao-share-btn@2x.png) 2x, url(/public/assets/images/icon/kakao-share-btn@3x.png) 3x);
  background-image: image-set(url(/public/assets/images/icon/kakao-share-btn.png) 1x, url(/public/assets/images/icon/kakao-share-btn@2x.png) 2x, url(/public/assets/images/icon/kakao-share-btn@3x.png) 3x);
}
.bonus-popup-root .sns-section .kakao-button:active {
  background-image: -webkit-image-set(url(/public/assets/images/icon/kakao-share-btn-pressed.png) 1x, url(/public/assets/images/icon/kakao-share-btn-pressed@2x.png) 2x, url(/public/assets/images/icon/kakao-share-btn-pressed@3x.png) 3x);
  background-image: image-set(url(/public/assets/images/icon/kakao-share-btn-pressed.png) 1x, url(/public/assets/images/icon/kakao-share-btn-pressed@2x.png) 2x, url(/public/assets/images/icon/kakao-share-btn-pressed@3x.png) 3x);
}

@media (max-width: 640px) {
  .bonus-popup-root {
    position: absolute;
    max-width: none;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    box-shadow: 0px 5px 5px #a0a0a0;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  .bonus-popup-root .section-wrapper {
    height: 263px;
  }
  .bonus-popup-root .link-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
  .bonus-popup-root .link-section .control-pad {
    position: absolute;
    top: 0px;
    width: 100%;
    padding: 12px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 60px;
  }
  .bonus-popup-root .link-section .control-pad .controller {
    width: 96px;
    height: 4px;
    border-radius: 2px;
    background-color: #000;
  }
  .bonus-popup-root .link-section .link {
    border: 1px solid #4ea3e1;
    padding: 8px 7px;
    color: #000;
    font-size: 16px;
    max-width: 70vw;
    overflow: hidden;
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    border-radius: 4px;
  }
  .bonus-popup-root .link-section .copy-button {
    width: 82px;
    height: 38px;
    background-color: #4ea3e1;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    border-radius: 7px;
    flex-shrink: 0;
  }
  .bonus-popup-root .link-section .copy-button:active {
    background-color: #2878b1;
  }
  .bonus-popup-root .divider {
    display: flex;
    align-items: center;
    gap: 9px;
  }
  .bonus-popup-root .divider .share-text {
    font-size: 20px;
    font-weight: 500;
  }
  .bonus-popup-root .divider .line {
    flex-grow: 1;
    background-color: #7d7d7d;
    height: 1px;
  }
  .bonus-popup-root .sns-section {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .bonus-popup-root .sns-section button {
    width: 48px;
    height: 48px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  .bonus-popup-root .sns-section button:active {
    filter: invert(36%);
  }
  .bonus-popup-root .sns-section .insta-button {
    background-image: -webkit-image-set(url(/public/assets/images/icon/insta-black.png) 1x, url(/public/assets/images/icon/insta-black@2x.png) 2x, url(/public/assets/images/icon/insta-black@3x.png) 3x);
    background-image: image-set(url(/public/assets/images/icon/insta-black.png) 1x, url(/public/assets/images/icon/insta-black@2x.png) 2x, url(/public/assets/images/icon/insta-black@3x.png) 3x);
  }
  .bonus-popup-root .sns-section .twit-button {
    background-image: -webkit-image-set(url(/public/assets/images/icon/twit-black.png) 1x, url(/public/assets/images/icon/twit-black@2x.png) 2x, url(/public/assets/images/icon/twit-black@3x.png) 3x);
    background-image: image-set(url(/public/assets/images/icon/twit-black.png) 1x, url(/public/assets/images/icon/twit-black@2x.png) 2x, url(/public/assets/images/icon/twit-black@3x.png) 3x);
  }
  .bonus-popup-root .sns-section .facebook-button {
    background-image: -webkit-image-set(url(/public/assets/images/icon/face-black.png) 1x, url(/public/assets/images/icon/face-black@2x.png) 2x, url(/public/assets/images/icon/face-black@3x.png) 3x);
    background-image: image-set(url(/public/assets/images/icon/face-black.png) 1x, url(/public/assets/images/icon/face-black@2x.png) 2x, url(/public/assets/images/icon/face-black@3x.png) 3x);
  }
  .bonus-popup-root .sns-section .kakao-button {
    background-image: -webkit-image-set(url(/public/assets/images/icon/kakao-black.png) 1x, url(/public/assets/images/icon/kakao-black@2x.png) 2x, url(/public/assets/images/icon/kakao-black@3x.png) 3x);
    background-image: image-set(url(/public/assets/images/icon/kakao-black.png) 1x, url(/public/assets/images/icon/kakao-black@2x.png) 2x, url(/public/assets/images/icon/kakao-black@3x.png) 3x);
  }
}/*# sourceMappingURL=bonus.popup.style.css.map */