.nextbutton-root {
  background-image: image-set(
    url(/public/assets/images/image/next-btn-bg.png) 1x,
    url(/public/assets/images/image/next-btn-bg@2x.png) 2x,
    url(/public/assets/images/image/next-btn-bg@3x.png) 3x
  );
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 420px;
  width: 100%;
  height: 169px;
  position: relative;
  padding: 14px;

  &:disabled {
    .rect {
      background-color: rgba(205, 205, 205, 0.8);
      // background-color: #cdcdcd;
      // opacity: 0.8;

      .text {
        position: absolute;
        z-index: 20;
      }
      .hand {
        position: absolute;
        z-index: 20;
        right: 64px;
      }
    }
    &::after {
      content: '';
      position: absolute;
      z-index: 0;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: rgba(181, 181, 181, 0.85);
    }
  }

  &:hover:enabled {
    .rect {
      background-color: rgba(256, 256, 256, 0.6);
      border-color: #000;

      .text,
      .hand {
        filter: invert(100%);
      }

      .hand {
        transition: transform 0.1s ease-in-out;
        transform: translateX(16px);
      }
    }
  }

  &:active:enabled {
    .rect {
      border-color: #000;
      background-color: rgba(175, 175, 175, 0.6);
    }
  }

  .rect {
    z-index: 1;
    display: flex;
    align-items: center;
    padding: 43px 64px;
    justify-content: space-between;
    width: 355px;
    height: 141px;
    border: 3px solid #fff;
    transition: all 0.1s ease-in-out;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    .text {
      color: #fff;
      font-size: 40px;
      font-weight: bold;
    }

    .hand {
      transition: transform 0.1s ease-in-out;
      background-image: image-set(
        url(/public/assets/images/icon/hand.png) 1x,
        url(/public/assets/images/icon/hand@2x.png) 2x,
        url(/public/assets/images/icon/hand@3x.png) 3x
      );
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      width: 48px;
      height: 48px;
    }

    &:disabled {
      background-color: rgba(205, 205, 205, 0.8);
    }
  }
}

@media (max-width: 640px) {
  .nextbutton-root {
    display: flex;
    align-items: flex-end;
    justify-content: stretch;
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-radius: 12px;
    padding: 0px;

    &:disabled {
      &::after {
        content: '';
        position: absolute;
        z-index: 0;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(181, 181, 181, 0.38);
      }
    }
    .rect {
      border: none;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      width: 100%;
      height: 80px;
      backdrop-filter: none;
      .text {
        font-size: 32px;
      }

      .hand {
        width: 44px;
        height: 44px;
      }
    }

    &:active:enabled {
      .text,
      .hand {
        filter: invert(100%);
      }
      .rect {
        background-color: rgba(256, 256, 256, 0.75);
      }
    }

    &:disabled {
      .rect {
        background-color: rgba(217, 217, 217, 0.85);
      }
    }
  }
}
