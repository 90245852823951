.result-button-root {
  background-image: image-set(
    url(/public/assets/images/image/result-btn.png) 1x,
    url(/public/assets/images/image/result-btn@2x.png) 2x,
    url(/public/assets/images/image/result-btn@3x.png) 3x
  );
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 308px;
  height: 314px;
  position: relative;

  &:disabled {
    &::after {
      content: '';
      position: absolute;
      z-index: 0;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: rgba(196, 196, 196, 0.52);
    }

    .rect {
      background-color: rgba(205, 205, 205, 0.8);
    }
  }

  &:hover:enabled {
    .rect {
      background-color: rgba(256, 256, 256, 0.6);
      border-color: #000;

      .text,
      .hand {
        filter: invert(100%);
      }

      .hand {
        transition: transform 0.1s ease-in-out;
        transform: translateX(16px);
      }
    }
  }

  &:active:enabled {
    .rect {
      border-color: #000;
      background-color: rgba(175, 175, 175, 0.6);
    }
  }

  .rect {
    z-index: 1;
    display: flex;
    gap: 9px;
    align-items: flex-start;
    padding: 12px 24px;
    justify-content: space-between;
    width: 100%;
    height: 133px;
    transition: all 0.1s ease-in-out;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);

    .text {
      flex-grow: 1;
      text-align: left;
      letter-spacing: -0.2%;
      color: #fff;
      font-size: 38px;
      font-weight: bold;
    }

    .hand {
      transition: transform 0.1s ease-in-out;
      background-image: image-set(
        url(/public/assets/images/icon/hand.png) 1x,
        url(/public/assets/images/icon/hand@2x.png) 2x,
        url(/public/assets/images/icon/hand@3x.png) 3x
      );
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      width: 48px;
      height: 48px;
    }
  }
}

@media (max-width: 1080px) {
  .result-button-root {
    width: 358px;
    height: 200px;
    border-radius: 12px;
    overflow: hidden;

    &:disabled {
      .rect {
        background-color: rgba(217, 217, 217, 0.85);
      }
    }

    &:active:enabled {
      .text,
      .hand {
        filter: invert(100%);
      }
      .rect {
        background-color: rgba(256, 256, 256, 0.75);
      }
    }

    .rect {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      height: 80px;
      padding: 18px 15px;
      align-items: center;
      .text {
        font-size: 28px;
      }

      .hand {
        width: 44px;
        height: 44px;
      }
    }
  }
}
