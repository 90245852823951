$main: #ada7c9;
$secondary: #d7b9d5;
$tertiary: #90a8c3;
$blue: #4ea3e1;
$blue-light: #d8eeff;
$blue-light-2: #f2faff;
$blue-darken-1: #036fbd;
$blue-darken-2: #93b3cb;
$blue-darken-3: #1976ba;
$blue-darken-4: #dfe7ed;
$red: #ff0000;
$white: #ffffff;
$black: #000000;
