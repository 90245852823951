.c-checkbox-root .checkbox {
  display: none;
}
.c-checkbox-root .checkbox:checked + .container .check-box .check {
  display: block;
}
.c-checkbox-root .checkbox:checked + .container .label {
  font-weight: 400;
}
.c-checkbox-root .container {
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
}
.c-checkbox-root .container .check-box {
  position: relative;
  width: 28px;
  height: 28px;
  background-image: -webkit-image-set(url(/public/assets/images/icon/uncheck.png) 1x, url(/public/assets/images/icon/uncheck@2x.png) 2x, url(/public/assets/images/icon/uncheck@3x.png) 3x);
  background-image: image-set(url(/public/assets/images/icon/uncheck.png) 1x, url(/public/assets/images/icon/uncheck@2x.png) 2x, url(/public/assets/images/icon/uncheck@3x.png) 3x);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.c-checkbox-root .container .check-box .check {
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  transition: width 0.2s ease-out;
  height: 36px;
  background-image: -webkit-image-set(url(/public/assets/images/icon/checked.png) 1x, url(/public/assets/images/icon/checked@2x.png) 2x, url(/public/assets/images/icon/checked@3x.png) 3x);
  background-image: image-set(url(/public/assets/images/icon/checked.png) 1x, url(/public/assets/images/icon/checked@2x.png) 2x, url(/public/assets/images/icon/checked@3x.png) 3x);
}
.c-checkbox-root .container .label {
  font-weight: 20px;
  font-weight: 300;
}

@media (max-width: 760px) {
  .c-checkbox-root .checkbox:checked + .container .label {
    font-weight: 500;
  }
  .c-checkbox-root .container .label {
    font-weight: 400;
  }
}/*# sourceMappingURL=checkbox.style.css.map */