.cinput-root {
  position: relative;
}
.cinput-root .label {
  position: absolute;
  left: 8px;
  top: -10px;
  z-index: 1;
  padding: 0px 4px;
  background-color: #fff;
  font-size: 12px;
  color: #4ea3e1;
}
.cinput-root .input {
  border: 1px solid #4ea3e1;
  border-radius: 4px;
  padding: 8px;
  font-size: 18px;
  background-color: #fff;
  caret-color: #007ad2;
}
.cinput-root .input::-moz-placeholder {
  color: #a8a8a8;
}
.cinput-root .input:-ms-input-placeholder {
  color: #a8a8a8;
}
.cinput-root .input::placeholder {
  color: #a8a8a8;
}
.cinput-root .img {
  position: absolute;
  top: 9px;
  right: 4px;
  display: none;
  z-index: 2;
  width: 28px;
  height: 28px;
}
.cinput-root .fill {
  border-width: 3px;
}
.cinput-root:focus-within .input {
  border-width: 3px;
}
.cinput-root.caution .label {
  color: #ffc2c2;
}
.cinput-root.caution .input {
  border-color: #ffc2c2;
  color: #ff0000;
  caret-color: #ff0000;
}
.cinput-root.caution .img {
  display: inline-block;
}
.cinput-root.caution .img.vibration {
  -webkit-animation: vibration 0.1s infinite;
          animation: vibration 0.1s infinite;
}

@-webkit-keyframes vibration {
  from {
    transform: translateX(1px);
  }
  to {
    transform: translateX(-1px);
  }
}

@keyframes vibration {
  from {
    transform: translateX(1px);
  }
  to {
    transform: translateX(-1px);
  }
}
@media (max-width: 640px) {
  .cinput-root {
    width: 100%;
  }
  .cinput-root .label {
    left: 0px;
    top: -20px;
    padding: 0px;
    font-weight: bold;
  }
  .cinput-root .input {
    width: 100%;
    border-radius: 12px;
    background-color: #efeef4;
    font-size: 16px;
    border-color: #efeef4;
  }
  .cinput-root .input::-moz-placeholder {
    color: #878787;
  }
  .cinput-root .input:-ms-input-placeholder {
    color: #878787;
  }
  .cinput-root .input::placeholder {
    color: #878787;
  }
  .cinput-root:focus-within .input {
    background-color: #f9f9f9;
    border-color: #4ea3e1;
  }
  .cinput-root.caution .input {
    border-color: #ffc2c2;
  }
}/*# sourceMappingURL=input.style.css.map */