.quiz-last-root {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 143px;
}
.quiz-last-root .content-wrapper {
  margin-top: 108px;
}
.quiz-last-root .content-wrapper .checkbox-wrapper {
  margin-top: 52px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.quiz-last-root .question {
  font-weight: bold;
  font-size: 48px;
}

@media (max-width: 1300px) {
  .quiz-last-root {
    gap: 40px;
  }
}
@media (max-width: 1080px) {
  .quiz-last-root {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 10%;
    gap: 32px;
  }
  .quiz-last-root .content-wrapper {
    margin-top: 32px;
  }
  .quiz-last-root .question {
    font-size: 36px;
  }
}
@media (max-width: 640px) {
  .quiz-last-root {
    flex-direction: column;
    padding: 0;
    align-items: flex-start;
    gap: 32px;
  }
  .quiz-last-root .content-wrapper {
    margin-top: 0px;
  }
  .quiz-last-root .content-wrapper .checkbox-wrapper {
    margin-top: 44px;
  }
  .quiz-last-root .question {
    font-size: 36px;
  }
}/*# sourceMappingURL=quiz.last.style.css.map */