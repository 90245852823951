.c-blue-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  background-color: #4ea3e1;
  color: #ffffff;
  border-radius: 7px;
  font-weight: bold;
  font-size: 20px;
  padding: 14px 0px;
  border: 1px solid transparent;
}
.c-blue-button span {
  color: #fff;
}
.c-blue-button:hover:enabled {
  background-color: #d8eeff;
  border: 1px solid #036fbd;
}
.c-blue-button:hover:enabled * {
  color: #036fbd;
}
.c-blue-button:active:enabled {
  background-color: #93b3cb;
  color: #036fbd;
  border: 1px solid transparent;
}
.c-blue-button:disabled {
  background-color: #bcbcbc;
  color: #fff;
}

@media (max-width: 640px) {
  .c-blue-button {
    border-radius: 12px;
  }
  .c-blue-button:not(:disabled) {
    box-shadow: 3px 3px 3px #f1f1f1;
  }
  .c-blue-button:active:enabled {
    box-shadow: none;
  }
}/*# sourceMappingURL=cbutton.blue.style.css.map */