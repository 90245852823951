.progressbar-root {
  width: 100%;
  height: 4px;
  background-color: #f9f9f9;
  position: relative;

  .progress {
    transition: width 0.1s ease-in-out;
    height: 100%;
    background-color: #000000;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
  }
}
