@import '../../../../styles/color';

.c-blue-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  background-color: $blue;
  color: $white;
  border-radius: 7px;
  font-weight: bold;
  font-size: 20px;
  padding: 14px 0px;
  border: 1px solid transparent;

  span {
    color: #fff;
  }

  &:hover:enabled {
    background-color: $blue-light;

    * {
      color: $blue-darken-1;
    }

    border: 1px solid $blue-darken-1;
  }

  &:active:enabled {
    background-color: $blue-darken-2;
    color: $blue-darken-1;
    border: 1px solid transparent;
  }

  &:disabled {
    background-color: #bcbcbc;
    color: #fff;
  }
}

@media (max-width: 640px) {
  .c-blue-button {
    border-radius: 12px;
    &:not(:disabled) {
      box-shadow: 3px 3px 3px #f1f1f1;
    }
    &:active:enabled {
      box-shadow: none;
    }
  }
}
