.cinput-root {
  position: relative;

  .label {
    position: absolute;
    left: 8px;
    top: -10px;
    z-index: 1;
    padding: 0px 4px;
    background-color: #fff;
    font-size: 12px;
    color: #4ea3e1;
  }

  .input {
    border: 1px solid #4ea3e1;
    border-radius: 4px;
    padding: 8px;
    font-size: 18px;
    background-color: #fff;
    caret-color: #007ad2;

    &::placeholder {
      color: #a8a8a8;
    }
  }

  .img {
    position: absolute;
    top: 9px;
    right: 4px;
    display: none;
    z-index: 2;
    width: 28px;
    height: 28px;
  }
  .fill {
    border-width: 3px;
  }

  &:focus-within {
    .input {
      border-width: 3px;
    }
  }

  &.caution {
    .label {
      color: #ffc2c2;
    }
    .input {
      border-color: #ffc2c2;
      color: #ff0000;
      caret-color: #ff0000;
    }
    .img {
      display: inline-block;
    }
    .img.vibration {
      animation: vibration 0.1s infinite;
    }
  }
}

@keyframes vibration {
  from {
    transform: translateX(1px);
  }
  to {
    transform: translateX(-1px);
  }
}

@media (max-width: 640px) {
  .cinput-root {
    width: 100%;
    .label {
      left: 0px;
      top: -20px;
      padding: 0px;
      font-weight: bold;
    }
    .input {
      width: 100%;
      border-radius: 12px;
      background-color: #efeef4;
      font-size: 16px;
      border-color: #efeef4;
      &::placeholder {
        color: #878787;
      }
    }
    .img {
    }
    &:focus-within {
      .input {
        background-color: #f9f9f9;
        border-color: #4ea3e1;
      }
    }

    &.caution {
      .label {
      }
      .input {
        border-color: #ffc2c2;
        &::placeholder {
        }
      }
      .img {
      }
    }
  }
}
