.main-root {
  padding: 192px 70px;
  display: flex;
  justify-content: center;
  gap: 99px;
}
.main-root .subtitle {
  font-weight: 500;
  font-size: 24px;
  color: #ffffff;
}
.main-root .title {
  font-weight: 900;
  font-size: 48px;
  color: #ffffff;
}
.main-root .paragraph {
  display: block;
  max-width: 640px;
  margin-top: 32px;
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  letter-spacing: -0.6px;
}
.main-root .email-form {
  position: relative;
  margin-top: 56px;
  background-color: #ffffff;
  max-width: 550px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  padding: 4px;
}
.main-root .email-form .email-input {
  flex-grow: 1;
  padding: 12px 8px 11px 10px;
  color: #000000;
  font-size: 20px;
  font-weight: 500;
}
.main-root .email-form .email-input::-moz-placeholder {
  color: #000000;
}
.main-root .email-form .email-input:-ms-input-placeholder {
  color: #000000;
}
.main-root .email-form .email-input::placeholder {
  color: #000000;
}
.main-root .email-form .email-caution {
  position: absolute;
  width: 370px;
  padding: 4px;
  top: 12px;
  left: 8px;
  background-color: #fff;
  color: #ff0000;
  font-size: 20px;
  font-weight: 500;
  -webkit-animation: vibration 0.1s;
          animation: vibration 0.1s;
  -webkit-animation-iteration-count: 3;
          animation-iteration-count: 3;
}
.main-root .phone-img-container {
  position: relative;
  width: 570px;
  height: 450px;
  display: flex;
  align-items: flex-end;
}
.main-root .phone-img-container img {
  position: absolute;
}
.main-root .phone-img-container img:nth-child(1) {
  left: 0;
  width: 158px;
  height: 323px;
  margin-bottom: 40px;
}
.main-root .phone-img-container img:nth-child(2) {
  z-index: 10;
  left: 182px;
  width: 219px;
  height: 449px;
}
.main-root .phone-img-container img:nth-child(3) {
  left: 425px;
  width: 158px;
  height: 323px;
  margin-bottom: 40px;
}

@-webkit-keyframes vibration {
  from {
    transform: translateX(1px);
  }
  to {
    transform: translateX(-1px);
  }
}

@keyframes vibration {
  from {
    transform: translateX(1px);
  }
  to {
    transform: translateX(-1px);
  }
}
@media (max-width: 1400px) {
  .main-root {
    flex-direction: column-reverse;
  }
  .main-root .phone-img-container {
    margin-bottom: 32px;
    margin-top: 36px;
  }
}
@media (max-width: 760px) {
  .main-root {
    padding: 24px 24px 196px 24px;
  }
}
@media (max-width: 640px) {
  .main-root .email-form .email-input {
    font-size: 14px;
  }
  .main-root .email-form .submit-button {
    font-size: 14px;
  }
  .main-root .phone-img-container {
    width: 300px;
  }
  .main-root .phone-img-container img:nth-child(1) {
    transform: rotate(-20deg);
    left: 20px;
  }
  .main-root .phone-img-container img:nth-child(2) {
    left: 62px;
    z-index: 10;
  }
  .main-root .phone-img-container img:nth-child(3) {
    left: 160px;
    transform: rotate(20deg);
  }
}/*# sourceMappingURL=home.style.css.map */