.nextbutton-root {
  background-image: -webkit-image-set(url(/public/assets/images/image/next-btn-bg.png) 1x, url(/public/assets/images/image/next-btn-bg@2x.png) 2x, url(/public/assets/images/image/next-btn-bg@3x.png) 3x);
  background-image: image-set(url(/public/assets/images/image/next-btn-bg.png) 1x, url(/public/assets/images/image/next-btn-bg@2x.png) 2x, url(/public/assets/images/image/next-btn-bg@3x.png) 3x);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 420px;
  width: 100%;
  height: 169px;
  position: relative;
  padding: 14px;
}
.nextbutton-root:disabled .rect {
  background-color: rgba(205, 205, 205, 0.8);
}
.nextbutton-root:disabled .rect .text {
  position: absolute;
  z-index: 20;
}
.nextbutton-root:disabled .rect .hand {
  position: absolute;
  z-index: 20;
  right: 64px;
}
.nextbutton-root:disabled::after {
  content: "";
  position: absolute;
  z-index: 0;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(181, 181, 181, 0.85);
}
.nextbutton-root:hover:enabled .rect {
  background-color: rgba(255, 255, 255, 0.6);
  border-color: #000;
}
.nextbutton-root:hover:enabled .rect .text,
.nextbutton-root:hover:enabled .rect .hand {
  filter: invert(100%);
}
.nextbutton-root:hover:enabled .rect .hand {
  transition: transform 0.1s ease-in-out;
  transform: translateX(16px);
}
.nextbutton-root:active:enabled .rect {
  border-color: #000;
  background-color: rgba(175, 175, 175, 0.6);
}
.nextbutton-root .rect {
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 43px 64px;
  justify-content: space-between;
  width: 355px;
  height: 141px;
  border: 3px solid #fff;
  transition: all 0.1s ease-in-out;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}
.nextbutton-root .rect .text {
  color: #fff;
  font-size: 40px;
  font-weight: bold;
}
.nextbutton-root .rect .hand {
  transition: transform 0.1s ease-in-out;
  background-image: -webkit-image-set(url(/public/assets/images/icon/hand.png) 1x, url(/public/assets/images/icon/hand@2x.png) 2x, url(/public/assets/images/icon/hand@3x.png) 3x);
  background-image: image-set(url(/public/assets/images/icon/hand.png) 1x, url(/public/assets/images/icon/hand@2x.png) 2x, url(/public/assets/images/icon/hand@3x.png) 3x);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 48px;
  height: 48px;
}
.nextbutton-root .rect:disabled {
  background-color: rgba(205, 205, 205, 0.8);
}

@media (max-width: 640px) {
  .nextbutton-root {
    display: flex;
    align-items: flex-end;
    justify-content: stretch;
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-radius: 12px;
    padding: 0px;
  }
  .nextbutton-root:disabled::after {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(181, 181, 181, 0.38);
  }
  .nextbutton-root .rect {
    border: none;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    width: 100%;
    height: 80px;
    -webkit-backdrop-filter: none;
            backdrop-filter: none;
  }
  .nextbutton-root .rect .text {
    font-size: 32px;
  }
  .nextbutton-root .rect .hand {
    width: 44px;
    height: 44px;
  }
  .nextbutton-root:active:enabled .text,
.nextbutton-root:active:enabled .hand {
    filter: invert(100%);
  }
  .nextbutton-root:active:enabled .rect {
    background-color: rgba(255, 255, 255, 0.75);
  }
  .nextbutton-root:disabled .rect {
    background-color: rgba(217, 217, 217, 0.85);
  }
}/*# sourceMappingURL=nextbutton.style.css.map */