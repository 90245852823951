.test-root .test {
  width: 100px;
  height: 100px;
}
.test-root .test :nth-of-type(0) {
  background-color: red;
}
.test-root .test :nth-of-type(1) {
  background-color: red;
}
.test-root .test:nth-of-type(2) {
  background-color: blue;
}
.test-root:nth-child(9) {
  background-color: red;
}
.test-root .test:nth-child(1) {
  background-color: red;
}/*# sourceMappingURL=test.styles.css.map */