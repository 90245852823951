.mobile-home-root {
  padding: 100px 35px 64px 35px;
}
.mobile-home-root .header-bg {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  height: 457px;
  width: 100%;
}
.mobile-home-root .title {
  color: #fff;
  font-size: 85px;
  font-weight: bold;
  text-shadow: 3px 3px 6px rgba(100, 100, 100, 0.7);
  text-align: center;
}
.mobile-home-root .content {
  text-align: justify;
}
.mobile-home-root section:nth-of-type(1) .subtitle {
  margin-top: 32px;
  text-align: center;
}
.mobile-home-root section:nth-of-type(1) .content {
  margin-top: 20px;
}
.mobile-home-root section:nth-of-type(2) .subtitle {
  margin-top: 80px;
}
.mobile-home-root section:nth-of-type(2) .content {
  margin-top: 20px;
}
.mobile-home-root section:nth-of-type(3) .subtitle {
  margin-top: 72px;
  text-align: center;
}
.mobile-home-root section:nth-of-type(3) .content {
  margin-top: 36px;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}
.mobile-home-root section:nth-of-type(4) {
  margin-top: 96px;
}
.mobile-home-root section:nth-of-type(5) {
  margin-top: 88px;
}
.mobile-home-root .subtitle {
  font-size: 28px;
  font-weight: bold;
}
.mobile-home-root .subtitle .colored {
  color: #ada7c9;
}
.mobile-home-root .content {
  font-size: 16px;
  margin-top: 20px;
}
.mobile-home-root .img-container {
  width: 100vw;
  margin-left: -35px;
  position: relative;
  display: flex;
  flex-direction: column;
}
.mobile-home-root .img-container .wave {
  position: absolute;
  z-index: -1;
  bottom: 48px;
  width: 100%;
  height: 56px;
  background-image: -webkit-image-set(url(/public/assets/images/image/wave.png) 1x, url(/public/assets/images/image/wave@2x.png) 2x, url(/public/assets/images/image/wave@3x.png) 3x);
  background-image: image-set(url(/public/assets/images/image/wave.png) 1x, url(/public/assets/images/image/wave@2x.png) 2x, url(/public/assets/images/image/wave@3x.png) 3x);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.mobile-home-root .img-container .splash-screen {
  margin-top: 104px;
  align-self: center;
  width: 45%;
}
.mobile-home-root .img-container .cover {
  position: absolute;
  z-index: 2;
  bottom: 0px;
  width: 100%;
  height: 100px;
  background: linear-gradient(180deg, transparent, #fff);
}
.mobile-home-root .email-form {
  display: flex;
  flex-direction: column;
}
.mobile-home-root .app-screen-container {
  width: 100vw;
  margin-left: -35px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  background: radial-gradient(circle farthest-side, #ada7c9, transparent);
}
.mobile-home-root .app-screen-container .home-screen {
  position: absolute;
  width: 85%;
  aspect-ratio: auto;
}
.mobile-home-root .app-screen-container .post-screen {
  margin-top: 75%;
  align-self: flex-end;
  width: 85%;
  aspect-ratio: auto;
}
.mobile-home-root .section-title {
  font-size: 24px;
  font-weight: 500;
}
.mobile-home-root .divider {
  margin-top: 10px;
  height: 2px;
  width: 100%;
  background-color: #000000;
  margin-bottom: 34px;
}
.mobile-home-root .button-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.mobile-home-root .button-wrapper .img-button {
  width: 48px;
  height: 48px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.mobile-home-root .button-wrapper .img-button:nth-child(1) {
  background-image: -webkit-image-set(url(/public/assets/images/icon/insta-black.png) 1x, url(/public/assets/images/icon/insta-black@2x.png) 2x, url(/public/assets/images/icon/insta-black@3x.png) 3x);
  background-image: image-set(url(/public/assets/images/icon/insta-black.png) 1x, url(/public/assets/images/icon/insta-black@2x.png) 2x, url(/public/assets/images/icon/insta-black@3x.png) 3x);
}
.mobile-home-root .button-wrapper .img-button:nth-child(1):active {
  background-image: -webkit-image-set(url(/public/assets/images/icon/insta-pressed.png) 1x, url(/public/assets/images/icon/insta-pressed@2x.png) 2x, url(/public/assets/images/icon/insta-pressed@3x.png) 3x);
  background-image: image-set(url(/public/assets/images/icon/insta-pressed.png) 1x, url(/public/assets/images/icon/insta-pressed@2x.png) 2x, url(/public/assets/images/icon/insta-pressed@3x.png) 3x);
}
.mobile-home-root .button-wrapper .img-button:nth-child(2) {
  background-image: -webkit-image-set(url(/public/assets/images/icon/twit-black.png) 1x, url(/public/assets/images/icon/twit-black@2x.png) 2x, url(/public/assets/images/icon/twit-black@3x.png) 3x);
  background-image: image-set(url(/public/assets/images/icon/twit-black.png) 1x, url(/public/assets/images/icon/twit-black@2x.png) 2x, url(/public/assets/images/icon/twit-black@3x.png) 3x);
}
.mobile-home-root .button-wrapper .img-button:nth-child(2):active {
  background-image: -webkit-image-set(url(/public/assets/images/icon/twit-pressed.png) 1x, url(/public/assets/images/icon/twit-pressed@2x.png) 2x, url(/public/assets/images/icon/twit-pressed@3x.png) 3x);
  background-image: image-set(url(/public/assets/images/icon/twit-pressed.png) 1x, url(/public/assets/images/icon/twit-pressed@2x.png) 2x, url(/public/assets/images/icon/twit-pressed@3x.png) 3x);
}
.mobile-home-root .button-wrapper .img-button:nth-child(3) {
  background-image: -webkit-image-set(url(/public/assets/images/icon/tictok-black.png) 1x, url(/public/assets/images/icon/tictok-black@2x.png) 2x, url(/public/assets/images/icon/tictok-black@3x.png) 3x);
  background-image: image-set(url(/public/assets/images/icon/tictok-black.png) 1x, url(/public/assets/images/icon/tictok-black@2x.png) 2x, url(/public/assets/images/icon/tictok-black@3x.png) 3x);
}
.mobile-home-root .button-wrapper .img-button:nth-child(3):active {
  background-image: -webkit-image-set(url(/public/assets/images/icon/tictok-pressed.png) 1x, url(/public/assets/images/icon/tictok-pressed@2x.png) 2x, url(/public/assets/images/icon/tictok-pressed@3x.png) 3x);
  background-image: image-set(url(/public/assets/images/icon/tictok-pressed.png) 1x, url(/public/assets/images/icon/tictok-pressed@2x.png) 2x, url(/public/assets/images/icon/tictok-pressed@3x.png) 3x);
}
.mobile-home-root .button-wrapper .img-button:nth-child(4) {
  background-image: -webkit-image-set(url(/public/assets/images/icon/mail-black.png) 1x, url(/public/assets/images/icon/mail-black@2x.png) 2x, url(/public/assets/images/icon/mail-black@3x.png) 3x);
  background-image: image-set(url(/public/assets/images/icon/mail-black.png) 1x, url(/public/assets/images/icon/mail-black@2x.png) 2x, url(/public/assets/images/icon/mail-black@3x.png) 3x);
}
.mobile-home-root .button-wrapper .img-button:nth-child(4):active {
  background-image: -webkit-image-set(url(/public/assets/images/icon/mail-pressed.png) 1x, url(/public/assets/images/icon/mail-pressed@2x.png) 2x, url(/public/assets/images/icon/mail-pressed@3x.png) 3x);
  background-image: image-set(url(/public/assets/images/icon/mail-pressed.png) 1x, url(/public/assets/images/icon/mail-pressed@2x.png) 2x, url(/public/assets/images/icon/mail-pressed@3x.png) 3x);
}/*# sourceMappingURL=home.mobile.style.css.map */