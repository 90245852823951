@import '../../../../styles/color';

.default-popup-root {
  width: fit-content;
  z-index: 10;
  box-shadow: 0px 5px 3px #a0a0a0;
  background-color: $white;
  border-radius: 7px;
  padding: 40px 64px 48px 64px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .smile-img {
    margin-bottom: 35px;
    width: 88px;
    height: 88px;
    background-image: image-set(
      url(/public/assets/images/image/smile.png) 1x,
      url(/public/assets/images/image/smile@2x.png) 2x,
      url(/public/assets/images/image/smile@3x.png) 3x
    );
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }

  .coming-soon {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 12px;
  }

  .link-copy {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 9px;
  }

  .text {
    text-align: center;
    margin-bottom: 36px;
    font-size: 24px;
  }
}

@media (max-width: 640px) {
  .default-popup-root {
    padding: 32px 30px 40px 30px;
    max-width: 90vh;

    .smile-img {
      margin-bottom: 8px;
      width: 72px;
      height: 72px;
    }

    .link-copy {
      margin-bottom: 9px;
      font-size: 20px;
      font-weight: bold;
    }

    .text {
      font-size: 16px;
    }

    .discard-button {
      font-size: 16px;
    }
  }
}
