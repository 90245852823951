.bonus-button-root {
  position: relative;
  background-image: image-set(
    url(/public/assets/images/image/share-button.png) 1x,
    url(/public/assets/images/image/share-button@2x.png) 2x,
    url(/public/assets/images/image/share-button@3x.png) 3x
  );
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 458px;
  height: 326px;

  &:disabled {
    &::after {
      content: '';
      position: absolute;
      z-index: 0;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: rgba(196, 196, 196, 0.52);
    }

    .rect {
      background-color: rgba(205, 205, 205, 0.8);
    }
  }

  &:hover:enabled {
    .rect {
      background-color: rgba(256, 256, 256, 0.6);
      border-color: #000;

      .text,
      .hand {
        filter: invert(100%);
      }

      .hand {
        transition: transform 0.1s ease-in-out;
        transform: translateX(16px);
      }
    }
  }

  &:active:enabled {
    .rect {
      border-color: #000;
      background-color: rgba(175, 175, 175, 0.6);
    }
  }

  .rect {
    z-index: 1;
    display: flex;
    align-items: flex-start;
    padding: 12px 24px;
    justify-content: space-between;
    width: 100%;
    height: 133px;
    transition: all 0.1s ease-in-out;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);

    .text {
      flex-grow: 1;
      text-align: left;
      letter-spacing: -0.2%;
      color: #fff;
      font-size: 38px;
      font-weight: bold;
    }

    .hand {
      flex-shrink: 0;
      transition: transform 0.1s ease-in-out;
      background-image: image-set(
        url(/public/assets/images/icon/hand.png) 1x,
        url(/public/assets/images/icon/hand@2x.png) 2x,
        url(/public/assets/images/icon/hand@3x.png) 3x
      );
      transform: rotate(180deg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      width: 48px;
      height: 48px;
    }
  }
}

@media (max-width: 640px) {
  .bonus-button-root {
    align-items: center;
    justify-content: center;
    width: 358px;
    height: 178px;
    border-radius: 12px;
    padding: 19px 14px;

    &:disabled {
      .rect {
        background-color: rgba(217, 217, 217, 0.85);
        border: 1px solid transparent;
      }
    }

    &:active:enabled {
      .text,
      .hand {
        filter: invert(100%);
      }
      .rect {
        background-color: rgba(256, 256, 256, 0.75);
      }
    }

    .rect {
      width: 100%;
      height: 100%;
      padding: 26px 24px;
      border-radius: 12px;
      transition: all 0.1s ease-in-out;
      border: 1px solid #fff;

      .text {
        font-size: 32px;
      }

      .hand {
        transform: rotate(135deg);
        width: 32px;
        height: 36px;
      }
    }
  }
}
