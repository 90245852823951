.quiz-layout-root {
  width: 100%;
  padding: 0px 70px;
  height: 720px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.quiz-layout-root .backdrop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  transition: opacity 0.3s;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #000000;
  opacity: 0;
}
.quiz-layout-root .close-btn {
  width: 28px;
  height: 28px;
  margin-left: 20px;
  margin-top: 16px;
  background-image: -webkit-image-set(url(/public/assets/images/icon/close-button.png) 1x, url(/public/assets/images/icon/close-button@2x.png) 2x, url(/public/assets/images/icon/close-button@3x.png) 3x);
  background-image: image-set(url(/public/assets/images/icon/close-button.png) 1x, url(/public/assets/images/icon/close-button@2x.png) 2x, url(/public/assets/images/icon/close-button@3x.png) 3x);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
.quiz-layout-root .close-btn:active {
  background-image: -webkit-image-set(url(/public/assets/images/icon/close-click.png) 1x, url(/public/assets/images/icon/close-click@2x.png) 2x, url(/public/assets/images/icon/close-click@3x.png) 3x);
  background-image: image-set(url(/public/assets/images/icon/close-click.png) 1x, url(/public/assets/images/icon/close-click@2x.png) 2x, url(/public/assets/images/icon/close-click@3x.png) 3x);
}
.quiz-layout-root .back-btn {
  width: 28px;
  height: 28px;
  margin-left: 20px;
  margin-top: 16px;
  background-image: -webkit-image-set(url(/public/assets/images/icon/back-button.png) 1x, url(/public/assets/images/icon/back-button@2x.png) 2x, url(/public/assets/images/icon/back-button@3x.png) 3x);
  background-image: image-set(url(/public/assets/images/icon/back-button.png) 1x, url(/public/assets/images/icon/back-button@2x.png) 2x, url(/public/assets/images/icon/back-button@3x.png) 3x);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
.quiz-layout-root .back-btn:active {
  background-image: -webkit-image-set(url(/public/assets/images/icon/back-click.png) 1x, url(/public/assets/images/icon/back-click@2x.png) 2x, url(/public/assets/images/icon/back-click@3x.png) 3x);
  background-image: image-set(url(/public/assets/images/icon/back-click.png) 1x, url(/public/assets/images/icon/back-click@2x.png) 2x, url(/public/assets/images/icon/back-click@3x.png) 3x);
}
.quiz-layout-root .quiz-popup {
  max-width: 1800px;
  position: absolute;
  z-index: 2;
  width: calc(100% - 140px);
  height: 720px;
  background-color: #fff;
  transition: transform 0.3s ease-in-out;
}
.quiz-layout-root .content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 760px) {
  .quiz-layout-root {
    padding: 0px;
    width: 100%;
    height: 100vh;
  }
  .quiz-layout-root .backdrop {
    background-color: #fff;
  }
  .quiz-layout-root .quiz-popup {
    width: 100%;
    height: 100%;
    min-height: -webkit-max-content;
    min-height: -moz-max-content;
    min-height: max-content;
  }
  .quiz-layout-root .title-section {
    position: relative;
    height: 52px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
  }
  .quiz-layout-root .title-section .m-close-btn {
    position: absolute;
    left: 20px;
    width: 20px;
    height: 20px;
    background-image: -webkit-image-set(url(/public/assets/images/icon/m-close-btn.png) 1x, url(/public/assets/images/icon/m-close-btn@2x.png) 2x, url(/public/assets/images/icon/m-close-btn@3x.png) 3x);
    background-image: image-set(url(/public/assets/images/icon/m-close-btn.png) 1x, url(/public/assets/images/icon/m-close-btn@2x.png) 2x, url(/public/assets/images/icon/m-close-btn@3x.png) 3x);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  .quiz-layout-root .title-section .m-back-btn {
    position: absolute;
    left: 20px;
    width: 20px;
    height: 20px;
    background-image: -webkit-image-set(url(/public/assets/images/icon/m-back-btn.png) 1x, url(/public/assets/images/icon/m-back-btn@2x.png) 2x, url(/public/assets/images/icon/m-back-btn@3x.png) 3x);
    background-image: image-set(url(/public/assets/images/icon/m-back-btn.png) 1x, url(/public/assets/images/icon/m-back-btn@2x.png) 2x, url(/public/assets/images/icon/m-back-btn@3x.png) 3x);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  .quiz-layout-root .title-section .title {
    font-size: 20px;
    font-weight: 500;
    justify-self: center;
  }
  .quiz-layout-root .content {
    padding: 36px 16px 54px 16px;
  }
}/*# sourceMappingURL=quiz.layout.style.css.map */