.alert-popup-root {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 10;
  box-shadow: 0px 5px 3px #a0a0a0;
  background-color: #ffffff;
  border-radius: 7px;
  padding: 40px 64px 48px 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.alert-popup-root .smile-img {
  margin-bottom: 35px;
  width: 88px;
  height: 88px;
  background-image: -webkit-image-set(url(/public/assets/images/image/smile.png) 1x, url(/public/assets/images/image/smile@2x.png) 2x, url(/public/assets/images/image/smile@3x.png) 3x);
  background-image: image-set(url(/public/assets/images/image/smile.png) 1x, url(/public/assets/images/image/smile@2x.png) 2x, url(/public/assets/images/image/smile@3x.png) 3x);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
.alert-popup-root .coming-soon {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 12px;
}
.alert-popup-root .link-copy {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 9px;
}
.alert-popup-root .text {
  text-align: center;
  margin-bottom: 36px;
  font-size: 24px;
}

@media (max-width: 640px) {
  .alert-popup-root {
    padding: 32px 30px 40px 30px;
    max-width: 90vw;
  }
  .alert-popup-root .smile-img {
    margin-bottom: 17px;
    width: 54px;
    height: 54px;
  }
  .alert-popup-root .link-copy {
    margin-bottom: 9px;
    font-size: 20px;
    font-weight: bold;
  }
  .alert-popup-root .text {
    text-align: justify;
    font-size: 16px;
  }
  .alert-popup-root .discard-button {
    font-size: 16px;
  }
}/*# sourceMappingURL=alert.style.css.map */