.dropdown-root {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.dropdown-root .label {
  position: absolute;
  background-color: #fff;
  padding: 0px 4px;
  color: #4ea3e1;
  font-weight: 500;
  font-size: 12px;
  transition: top 0.1s ease-in-out;
  top: 11px;
  left: 8px;
}
.dropdown-root .dropdown-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border: 1px solid #bcd7eb;
  border-radius: 4px;
  padding: 10px 8px;
  height: 40px;
}
.dropdown-root .dropdown-container .text {
  font-size: 18px;
}
.dropdown-root .dropdown-container .arrow {
  transition: transform 0.1s ease-in-out;
  transform: rotate(180deg);
  background-image: -webkit-image-set(url(/public/assets/images/icon/arrow.png) 1x, url(/public/assets/images/icon/arrow@2x.png) 2x, url(/public/assets/images/icon/arrow@3x.png) 3x);
  background-image: image-set(url(/public/assets/images/icon/arrow.png) 1x, url(/public/assets/images/icon/arrow@2x.png) 2x, url(/public/assets/images/icon/arrow@3x.png) 3x);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 19px;
  height: 19px;
}
.dropdown-root .options-wrapper {
  position: absolute;
  z-index: 5;
  transition: height 0.1s ease-in-out, padding 0.1s ease-in-out;
  height: 0px;
  top: 56px;
  box-shadow: 3px 3px 3px #f1f1f1;
  border-radius: 7px;
  background-color: #fff;
}
.dropdown-root .options-wrapper .option-list {
  height: 100%;
  overflow-y: scroll;
}
.dropdown-root .options-wrapper .option-list::-webkit-scrollbar {
  width: 4px;
}
.dropdown-root .options-wrapper .option-list::-webkit-scrollbar-thumb {
  background-color: #c9c9c9;
  height: 30px;
  border-radius: 2px;
}
.dropdown-root .options-wrapper .option-list::-webkit-scrollbar-track {
  background-color: transparent;
}
.dropdown-root .options-wrapper .option-list .option {
  padding: 4px 8px;
  border-radius: 7px;
  font-size: 14px;
}
.dropdown-root .options-wrapper .option-list .option:hover, .dropdown-root .options-wrapper .option-list .option:active {
  background-color: #f2faff;
}
.dropdown-root.focused .label {
  top: -4px;
}
.dropdown-root.focused .dropdown-container {
  border-width: 3px;
}
.dropdown-root.focused .dropdown-container .arrow {
  transform: rotate(0deg);
}
.dropdown-root.focused .options-wrapper {
  height: 168px;
  padding: 8px;
}
.dropdown-root.selected .label {
  top: -4px;
}
.dropdown-root.selected .dropdown-container {
  border-width: 3px;
  border-color: #4ea3e1;
  padding: 8px;
}/*# sourceMappingURL=dropdown.style.css.map */