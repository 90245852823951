.bonus-root {
  display: flex;
  gap: 132px;
  align-items: center;
  justify-content: space-between;

  .text-section {
    .title {
      margin-top: 92px;
      font-size: 48px;
      font-weight: bold;
    }
    .content-text {
      font-size: 20px;
      margin-top: 40px;
      font-weight: 300;
    }
    .dropdown-wrapper {
      margin-top: 36px;
      display: flex;
      height: 48px;
      gap: 8px;
    }
  }
  .button-section {
    padding-top: 128px;
  }
}

@media (max-width: 1240px) {
  .bonus-root {
    flex-direction: column;
    gap: 32px;

    .text-section {
      .title {
        margin-top: 16px;
        font-size: 36px;
        font-weight: bold;
      }
      .content-text {
        font-size: 20px;
        margin-top: 8px;
        font-weight: 300;
      }
      .dropdown-wrapper {
        margin-top: 12px;
        display: flex;
        height: 48px;
        gap: 8px;
      }
    }
    .button-section {
      padding-top: 0px;
      margin-top: -20px;
      align-self: flex-end;
    }
  }
}

@media (max-width: 640px) {
  .bonus-root {
    display: flex;
    gap: 0vw;
    align-items: center;
    justify-content: space-between;

    .text-section {
      .title {
        margin-top: 0px;
        font-size: 28px;
        font-weight: bold;
      }
      .content-text {
        color: #595959;
        text-align: justify;
        font-size: 16px;
        margin-top: 20px;
        font-weight: 400;
      }
      .dropdown-wrapper {
        display: grid;
        row-gap: 32px;
        column-gap: 20px;
        grid-template-columns: 1fr 1fr;
        margin-top: 48px;
      }
    }
    .button-section {
      padding-top: 128px;
    }
  }
}
