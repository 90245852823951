@import '../../../styles/color';

.alert-popup-root {
  width: fit-content;
  box-shadow: 5px 5px 3px #505050;
  background-color: $white;
  border-radius: 7px;
  padding: 40px 64px 48px 64px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .coming-soon {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 12px;
  }

  .text {
    text-align: center;
    margin-bottom: 36px;
    font-size: 24px;
  }
}

@media (max-width: 640px) {
  .alert-popup-root {
    padding: 32px 30px 40px 20px;
    max-width: 80vh;

    .coming-soon {
      align-self: flex-start;
    }

    .text {
      align-self: flex-start;
      text-align: left;
      font-size: 16px;
    }

    .discard-button {
      font-size: 16px;
    }
  }
}
